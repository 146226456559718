<template>
	<div class="container">
		<div class="left-menu" :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }">
			<left-list :leftIndex="'4'" :isCollapse="isCollapse"></left-list>
		</div>
		<div class="right-content" :style="{ width: !isCollapse ? '88%' : '95.5%' }">
			<div class="top-nav">
				<top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
			</div>
			<div class="left-list">
				<div class="left-list-title">
					<span>点云数据管理</span>
				</div>
				<div class="left-list-item left-list-active" @click="leftClick('0')">
					<span>数据列表</span>
				</div>
				<!-- <div class="left-list-item" @click="leftClick('1')">
					<span>知识列表</span>
				</div> -->
			</div>
			<div class="right-next">
				<div class="title">
					<span>新增点云数据</span>
				</div>

				<div class="mt-20">
					<div class="knowledge-from">
						<div class="from-item">
							<span>公司名称 ：</span>
							<div class="from-item-input">
								<el-select v-model="fromList.companyid" clearable placeholder="请选择"
									@change="companyChange">
									<el-option v-for="item in company_list" :key="item.id" :label="item.name"
										:value="item.id">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="from-item" v-if="fromList.companyid">
							<span>矿山名称 ：</span>
							<div class="from-item-input">
								<el-select v-model="fromList.mine_id" clearable placeholder="请选择" @change="mineChange">
									<el-option v-for="item in mine_list" :key="item.id" :label="item.name"
										:value="item.id">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="from-item" v-if="fromList.mine_id">
							<span>磨机名称 ：</span>
							<div class="from-item-input">
								<el-select v-model="fromList.mill_id" clearable placeholder="请选择">
									<el-option v-for="item in mill_list" :key="item.id" :label="item.name"
										:value="item.id">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="from-item">
							<span>点云数据名称 ：</span>
							<div class="from-item-input">
								<el-input type="text" v-model="fromList.name" placeholder="名称"></el-input>
							</div>
						</div>
						<div class="from-item">
							<span>安装时间 ：</span>
							<div class="from-item-input">
								<el-date-picker v-model="fromList.install_time" type="datetime" placeholder="选择安装时间"
									align="right" :picker-options="pickerOptions" value-format="yyyy-MM-dd HH:mm:ss">
								</el-date-picker>
							</div>
						</div>
						<div class="from-item">
							<span>安装类型 ：</span>
							<div class="from-item-input">
								<el-select v-model="fromList.install_category" clearable placeholder="请选择">
									<el-option v-for="item in type_list" :key="item.id" :label="item.name"
										:value="item.id">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="from-item">
							<span>扫描时间 ：</span>
							<div class="from-item-input">
								<el-date-picker v-model="fromList.scanning_time" type="datetime" placeholder="选择扫描时间"
									align="right" :picker-options="pickerOptions" value-format="yyyy-MM-dd HH:mm:ss">
								</el-date-picker>
							</div>
						</div>
						<div class="from-item">
							<span>衬板附件 ：</span>
							<div class="from-item-upload">
								<el-upload class="u_img" action="url" accept=".html" ref="fileupload"
									:on-change="fileuploadChange" :headers="uploadHeaders" :auto-upload="false"
									:http-request="fileuploadFile" :limit="1">
									<el-button type="primary">选择文件</el-button>
									<div slot="tip" class="el-upload__tip">只能上传html格式文件</div>
								</el-upload>
							</div>
						</div>
						<div class="from-item">
							<span>衬板状态图 ：</span>
							<div class="from-item-upload">
								<el-upload ref="upload" action="url" list-type="picture-card" :on-change="uploadChange"
									:headers="uploadHeaders" :auto-upload="false" :http-request="uploadFile"
									:on-remove="handleRemove" :limit="5" :on-exceed="uploadExceed" accept=".png,.jepg,.jpg,.gif,.bump">
									<i class="el-icon-plus"></i>
								</el-upload>
							</div>
						</div>
						<div class="from-item">
							<el-button type="primary" @click="submitUpload">提交</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import leftList from "../../components/leftList.vue";
	import topHeader from "../../components/header.vue";
	import Cookies from 'js-cookie'
	export default {
		components: {
			leftList,
			topHeader,
		},
		inject: ["reload"],
		data() {
			return {
				pickerOptions: {
					shortcuts: [{
						text: '今天',
						onClick(picker) {
							picker.$emit('pick', new Date());
						}
					}, {
						text: '昨天',
						onClick(picker) {
							const date = new Date();
							date.setTime(date.getTime() - 3600 * 1000 * 24);
							picker.$emit('pick', date);
						}
					}, {
						text: '一周前',
						onClick(picker) {
							const date = new Date();
							date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', date);
						}
					}]
				},
				isCollapse: false,
				fromList: {
					name: "",
					file: '',
					companyid: '',
					mill_id: '',
					mine_id: '',
					img_file: [],
					install_time: '',
					install_category: '',
					scanning_time: ''

				},
				uploadHeaders: {
					"Remember-Token": Cookies.get("rememberToken"),
				},
				type_list: [{
					id: 1,
					name: '出料安装'
				}, {
					id: 2,
					name: '进料安装'
				}, {
					id: 3,
					name: '筒体安装'
				}],
				company_list: [],
				mine_list: [],
				mill_list: [],
				formDate: "",
			};
		},
		created() {
			this.getCompanylist();
		},
		methods: {
			companyChange(e) {
				this.getMinelist(e);
			},
			mineChange(e) {
				console.log(e);
				this.getMilllist(e);
			},
			getMilllist(id) {
				this.$get('mill/get_list', {
					mine_id: id
				}).then(res => {
					this.mill_list = res.data.data.data;
				})
			},
			getMinelist(id) {
				this.$get('mine/get_list', {
					company_id: id
				}).then(res => {
					this.mine_list = res.data.data.data;
				})
			},
			getCompanylist() {
				this.$get('company/get_list', {}).then(res => {
					this.company_list = res.data.data.data
				})
			},
			fileuploadFile(file) {
				this.formDate.append("liner_file", file.file);
			},
			fileuploadChange(file) {
				// console.log(file, fileList, index);
				this.fromList.file = file.raw;
				// console.log(this.fromList.file);
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
				let img_index = this.fromList.img_file.indexOf(file.raw.name);
				if(this.fromList.img_file.length == 1){
					this.fromList.img_file = [];
				}else{
					this.fromList.img_file.splice(img_index, img_index);
				}
				console.log(this.fromList.img_file);
			},
			uploadFile(file) {
				this.formDate.append("liner_images[]", file.file);
			},
			uploadExceed() {
				this.$message({
					message: '最多只可传五张状态图！',
					type: 'warning'
				});
			},
			uploadChange(file,fileList) {
				// console.log(file, fileList, index);
				let fileName = fileList[0].name.substring(fileList[0].name.lastIndexOf('.')+1);
				console.log(fileName);
				this.fromList.img_file.push(file.raw.name);
				// console.log(this.fromList.img_file);
			},
			submitUpload() {
				if(this.fromList.companyid == ''){
					this.$message({
						message:'请选择公司！',
						type:'error'
					})
					return false;
				}
				if(this.fromList.mine_id == ''){
					this.$message({
						message:'请选择矿山！',
						type:'error'
					})
					return false;
				}
				if(this.fromList.mill_id == ''){
					this.$message({
						message:'请选择磨机！',
						type:'error'
					})
					return false;
				}
				if(this.fromList.name == ''){
					this.$message({
						message:'请输入点云数据名称！',
						type:'error'
					})
					return false;
				}
				if(this.fromList.install_time == ''){
					this.$message({
						message:'请选择安装时间！',
						type:'error'
					})
					return false;
				}
				if(this.fromList.install_category == ''){
					this.$message({
						message:'请选择安装类型！',
						type:'error'
					})
					return false;
				}
				if(this.fromList.scanning_time == ''){
					this.$message({
						message:'请选择扫描时间！',
						type:'error'
					})
					return false;
				}
				if(this.fromList.file == ''){
					this.$message({
						message:'请上传衬板附件！',
						type:'error'
					})
					return false;
				}
				if(this.fromList.img_file.length == 0){
					this.$message({
						message:'请上传至少一张衬板状态图！',
						type:'error'
					})
					return false;
				}
				this.formDate = new FormData();
				this.$refs.upload.submit();
				this.$refs.fileupload.submit();
				this.formDate.append("company_id", this.fromList.companyid);
				this.formDate.append("mine_id", this.fromList.mine_id);
				this.formDate.append("mill_id", this.fromList.mill_id);
				this.formDate.append("name", this.fromList.name);
				this.formDate.append("install_time", this.fromList.install_time);
				this.formDate.append("install_category", this.fromList.install_category);
				this.formDate.append("scanning_time", this.fromList.scanning_time);
				let config = {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				};
				this.$axios
					.post(this.$store.state.httpUrl + "add/point", this.formDate, config)
					.then((res) => {
						if (res.data.status == 200) {
							this.$notify({
								title: "提示",
								message: "操作成功！",
								type: "success",
							});
							this.$router.push("/knowledge");
						} else {
							this.$notify.error({
								title: "提示",
								message: res.data.message,
							});
						}
						// console.log(res);
					})
					.catch((res) => {
						console.log(res);
					});
			},
			leftClick(index) {
				if (index == 0 && this.$route.path != "/knowledge") {
					this.$router.push("/knowledge");
				} else if (index == 1) {
					this.$router.push("/knowledgelist");
				} else {
					this.reload();
				}
			},
			menuitemClick(pushUrl) {
				// console.log(this.$route.path == pushUrl);
				if (this.$route.path == pushUrl) {
					this.reload();
				} else if (pushUrl) {
					this.$router.push(pushUrl);
				} else {
					this.$notify.error({
						title: "提示",
						message: "暂无页面",
					});
					this.reload();
				}
			},
			openMenu() {
				this.isCollapse = !this.isCollapse;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.right-content {
		height: 100vh;
		overflow-x: hidden;
		overflow-y: scroll;
		vertical-align: top;
		display: inline-block;

		&::-webkit-scrollbar {
			display: none;
		}

		.knowledge-from {
			margin-top: 30px;
			padding-left: 150px;

			.from-item {
				margin-top: 50px;

				span {
					&:nth-child(1) {
						width: 150px;
						display: inline-block;
					}
				}

				.from-item-upload {
					display: inline-block;
					vertical-align: top;
					margin: 0 0;
				}

				.from-item-input {
					display: inline-block;
					width: 220px;
				}
			}
		}

		.date-search {
			margin-top: 30px;

			.el-button {
				padding: 12px 55px;
			}
		}

		.left-list {
			width: 10%;
			height: 93vh;
			overflow-x: hidden;
			overflow-y: scroll;
			font-size: 14px;
			border-right: 20px solid #f2f2f2;
			display: inline-block;
			text-align: center;

			&::-webkit-scrollbar {
				display: none;
			}

			&-title {
				margin: 20px 0;
				font-size: 16px;
			}

			&-item {
				padding: 15px 0;
				cursor: pointer;
				transition: all 0.2s;

				&:hover {
					color: #fff;
					background-color: #3291f8;
				}
			}

			&-active {
				color: #fff;
				background-color: #3291f8;
			}
		}

		.right-next {
			height: 93vh;
			width: 88.5%;
			overflow-x: hidden;
			overflow-y: scroll;
			border-top: 20px solid #f2f2f2;
			padding: 20px;
			box-sizing: border-box;
			display: inline-block;
			vertical-align: top;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		.title {
			color: #000;
			height: 10px;
			padding-left: 15px;
			line-height: 10px;
			border-left: 5px solid #80c4f8;
			margin-bottom: 10px;
		}

		.top-nav {
			display: flex;
			box-sizing: border-box;
			justify-content: space-between;
			padding: 15px;

			.left-icon {
				i {
					cursor: pointer;
					color: #3291f8;
					font-size: 30px;
				}

				.el-icon-refresh-right {
					margin-left: 30px;
				}
			}

			.right-user {
				font-size: 14px;

				span {
					vertical-align: middle;
					margin-right: 20px;
				}

				.colse-btn {
					cursor: pointer;
					vertical-align: middle;
					border: 1px solid #999;
					display: inline-block;
					padding: 5px 20px;
					border-radius: 50px;

					span {
						margin-right: 10px;
					}

					i {
						vertical-align: middle;
					}
				}
			}
		}
	}

	.left-menu {
		display: inline-block;

		.iconyonghuzu {
			font-size: 18px;
			margin: 0 5px;
		}
	}

	.el-menu {
		width: 100%;
		background-color: #20222a;
		height: 100vh;
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		background-color: #20222a;
		color: #fff;
		height: 100vh;
		width: 100%;
	}

	.el-menu-item:focus,
	.el-menu-item:hover {
		color: #000;
		background-color: #ecf5ff;
	}

	.el-menu-item {
		color: #fff;

		i {
			color: #3291f8;
		}
	}

	.el-menu-item.is-active {
		background-color: #ecf5ff;
		color: #20222a;
	}

	.menu-h5 {
		margin: 20px;
		text-align: center;
		font-size: 20px;
	}

	.menu-icon {
		width: 100%;
		color: #fff;
		box-sizing: border-box;
		padding: 30px 22px;
	}
</style>
